;jQuery(document).ready(function ($) {
    "use strict"

    // Platform Detection
    const IOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream,
        Android = navigator.userAgent.toLowerCase().indexOf("android") > -1,
        Firefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1,
        Edge = navigator.userAgent.toLowerCase().indexOf('edge') > -1,
        IE = navigator.userAgent.toLowerCase().indexOf('msie ') > -1;

    // Browser Windows Sizes
    const browser = {
        w: document.documentElement.clientWidth,
        h: document.documentElement.clientHeight,
    }

    let vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)

    $(window).on('resize orientationchange', () => {
        browser.w = document.documentElement.clientWidth
        browser.h = document.documentElement.clientHeight

        let vh = window.innerHeight * 0.01
        document.documentElement.style.setProperty('--vh', `${vh}px`)
    });

    // Return GET parameter
    function getGETParam(param) {
        return new URLSearchParams(window.location.search).get(param);
    }

    // Set GET parameter
    function setGETParam(param, value) {
        let url = new URLSearchParams(window.location.search);
        url.set(param, value);
        let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + url;
        window.history.replaceState({}, document.title, newurl);
    }

    function removeGetParam(param) {
        let url = new URLSearchParams(window.location.search);
        url.delete(param);
        url = url.toString().length > 0 ? '?' + url : url;
        let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + url;
        window.history.replaceState({}, document.title, newurl);
    }

    function getPageNumLink() {
        let url = window.location.protocol + "//" + window.location.host + window.location.pathname + location.search;
        let newUrl = url;
        let regex = /\/{1,3}page\/(\d{1,100000})/;
        let page = url.match(regex);
        if (page) {
            newUrl = url.replace(regex, '');
            /* if (location.search) {
                 newUrl += location.search;
             }*/
        }
        return newUrl;
    }

    /* ----------------------------------- AOS Animation ------------------------------------ */
    {
        AOS.init({
            once: true,
            duration: 750,
        });
    }

    /* ----------------------------------- Scrollbar CSS Variable ------------------------------------ */
    {
        const getScrollbarWidth = () => {
            let outer = document.createElement("div");
            outer.style.visibility = "hidden";
            outer.style.width = "100px";
            outer.style.msOverflowStyle = "scrollbar"; // needed for WinJS apps

            document.body.appendChild(outer);

            let widthNoScroll = outer.offsetWidth;
            // force scrollbars
            outer.style.overflow = "scroll";

            // add innerdiv
            let inner = document.createElement("div");
            inner.style.width = "100%";
            outer.appendChild(inner);

            let widthWithScroll = inner.offsetWidth;

            // remove divs
            outer.parentNode.removeChild(outer);

            return widthNoScroll - widthWithScroll;
        }
        if ($('body').outerHeight() <= document.documentElement.clientHeight) {
            document.documentElement.style.setProperty('--scroll-width', 0);
        } else {
            document.documentElement.style.setProperty('--scroll-width', getScrollbarWidth() + 'px');
        }
    }

    /* ----------------------------------- Anchors ------------------------------------ */
    {
        $('a[href^="#"]').on('click', function (e) {
            e.preventDefault();

            const href = $(this).attr('href');

            if (!(href === '#')) {

                const section = $(href);

                if (section.length) {
                    const offset = $(section).offset().top;
                    $('html,body').animate({
                        scrollTop: offset
                    }, 600);
                }

            } else {
                return false
            }

        });
    }

    /* ----------------------------------- Mobile Navigation ------------------------------------ */
    {
        $(document).on('click', '.nav-main__toggle', function (e) {
            e.preventDefault()
            $('.nav-side').addClass('active')
            scrollLock.disablePageScroll()
        })

        // Close
        $(document).on('click', '.nav-side__close', function (e) {
            e.preventDefault()
            $('.nav-side').removeClass('active')
            scrollLock.enablePageScroll()
        })

        // Add Arrows
        $('.nav-side .menu li.menu-item-has-children > a').append(`
            <span class="sub-menu-toggle">
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 256 256" style="enable-background:new 0 0 256 256;" xml:space="preserve">
                          <polygon points="225.813,48.907 128,146.72 30.187,48.907 0,79.093 128,207.093 256,79.093 \t\t"/> 
                </svg>
            </span>
        `)
        $('.nav-side .menu li.menu-item-has-children>a .sub-menu-toggle').on('click', function (e) {
            e.preventDefault();

            $(this).parents('li').toggleClass('active');
            $(this).parents('li').find('.sub-menu').slideToggle('fast');


        });
    }

    /* ----------------------------------- Fixed Navigation ------------------------------------ */
    {
        const navFixed = $('.nav-main.fixed')
        let lastScroll = 0;
        $(window).on('scroll', function () {
            const scroll = $(this).scrollTop();
            if (scroll >= 450) {
                navFixed.addClass('active');
            } else {
                navFixed.removeClass('active');
            }
            lastScroll = scroll;
        });
    }

    /* ----------------------------------- Reviews Slider ------------------------------------ */
    {
        if (document.querySelector('.reviews-slider__swiper')) {
            const clientsReviewSlider = new Swiper('.reviews-slider__swiper', {
                loop: true,
                autoplay: {
                    delay: 4000,
                },
                spaceBetween: 40,
                grabCursor: true,
                pagination: {
                    el: '.reviews-slider-pagination',
                    clickable: true,
                }
            });
        }
    }

    /* ----------------------------------- Practice Areas Tabs & Accordion ------------------------------------ */
    {
        if ($('.services-tabs-list__item').length) {
            if (browser.w >= 992) {
                $('.services-tabs-list__item').on('click', function (e) {
                    e.preventDefault();
                    const index = $(this).attr('data-tab');
                    $(this).addClass('active');
                    $(this).siblings('.services-tabs-list__item').removeClass('active');
                    $('.services-tabs-content__item').each(function () {
                        if ($(this).attr('data-tab') === index) {
                            $(this).addClass('active').siblings('.services-tabs-content__item').removeClass('active');
                        }
                    });
                });
            } else {
                $('.services-tabs-list__item').on('click', function (e) {
                    e.preventDefault();
                    const index = $(this).attr('data-tab');
                    $(this).toggleClass('active');
                    $(this).siblings('.services-tabs-list__item').removeClass('active');
                    $('.services-tabs-list .services-tabs-content__item').each(function () {
                        if ($(this).attr('data-tab') === index) {
                            $(this).slideToggle('fast');
                        } else {
                            $(this).slideUp('fast');
                        }
                    });
                });
                $('.services-tabs-list .services-tabs-content__item.active').slideDown(1);
            }
        }
    }

    /* ----------------------------------- Team Slider ------------------------------------ */
    {
        if (document.querySelector('.team-slider-wrapper__swiper')) {
            const teamMembersSlider = new Swiper('.team-slider-wrapper__swiper', {
                loop: true,
                slidesPerView: 'auto',
                autoplay: {
                    delay: 5000,
                    pauseOnMouseEnter: true,
                    disableOnInteraction: true
                },
                pagination: {
                    el: '.team-slider-pagination',
                    clickable: true,
                },
            });
        }
    }

    /* ----------------------------------- Clients Slider ------------------------------------ */
    {
        if (document.querySelector('.clients-slider__swiper')) {
            const teamMembersSlider = new Swiper('.clients-slider__swiper', {
                loop: true,
                slidesPerView: 'auto',
                grabCursor: true,
                autoplay: {
                    delay: 5000,
                    pauseOnMouseEnter: true,
                    disableOnInteraction: true
                },
            });
        }
    }

    /* ----------------------------------- Practice Area Grid ------------------------------------ */
    {
        $('.practice-areas-grid .practice-area__info').each(function () {
            $(this).css({
                'height': `${$(this).outerHeight() - 52 - 25}px`
            })
        })
    }

    /* ----------------------------------- Popup Video ------------------------------------ */
    {
        $('.video-btn, .popup-youtube, .popup-vimeo, .popup-gmaps').magnificPopup({
            disableOn: 100,
            type: 'iframe',
            mainClass: 'mfp-fade',
            removalDelay: 160,
            preloader: false,
            fixedContentPos: false,
            iframe: {
                markup: '<div class="mfp-iframe-scaler">' +
                    '<div class="mfp-close"></div>' +
                    '<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>' +
                    '</div>', // HTML markup of popup, `mfp-close` will be replaced by the close button
                patterns: {
                    youtube: {
                        index: 'youtube.com/', // String that detects type of video (in this case YouTube). Simply via url.indexOf(index).

                        id: 'v=', // String that splits URL in a two parts, second part should be %id%
                        // Or null - full URL will be returned
                        // Or a function that should return %id%, for example:
                        // id: function(url) { return 'parsed id'; }

                        src: '//www.youtube.com/embed/%id%?autoplay=1&listType=playlist&rel=0' // URL that will be set as a source for iframe.
                    },
                    vimeo: {
                        index: 'vimeo.com/',
                        id: '/',
                        src: '//player.vimeo.com/video/%id%?autoplay=1'
                    },
                    gmaps: {
                        index: '//maps.google.',
                        src: '%id%&output=embed'
                    }

                    // you may add here more sources

                },

                srcAction: 'iframe_src', // Templating object key. First part defines CSS selector, second attribute. "iframe_src" means: find "iframe" and set attribute "src".
            }
        });

    }

    /* ----------------------------------- Search Modal ------------------------------------ */
    {
        $('.search-btn').on('click', function (e) {
            e.preventDefault();
            $('.search-modal').addClass('active');
        });

        $('.search-modal .close-search-modal').on('click', function (e) {
            e.preventDefault();
            $('.search-modal').removeClass('active');
        });

    }

    /* ----------------------------------- Selectric ------------------------------------ */
    {
        $('.selectric-select-component').each(function () {
            $(this).selectric({
                maxHeight: 200
            });
        })
    }

    /* ----------------------------------- Case Studies Load More ------------------------------------ */
    {
        $(document).on('click', '.case-studies-template .btn-load-more', function () {
            let data = new FormData();

            // Ajax Settings
            data.append('nonce_code', PHP.load_more_nonce);
            data.append('action', 'cases_load_more');
            data.append('query', PHP.posts);
            data.append('page', $('.btn-load-more').attr('data-current-page'));
            data.append('pagenum_link', getPageNumLink());

            if (getGETParam('date')) {
                data.append('date', getGETParam('date'));
            }

            if (getGETParam('category')) {
                data.append('category', getGETParam('category'));
            }

            $.ajax({
                url: PHP.ajaxurl,
                data: data,
                type: 'POST',
                cache: false,
                dataType: 'json',
                processData: false,
                contentType: false,
                beforeSend: function (xhr) {
                    $('html,body').addClass('wait');
                    $('.filters-block').addClass('disabled');
                },
                complete: function (response) {
                    let $posts = $(response.responseJSON.posts);
                    let $pagination = $(response.responseJSON.pagination);

                    // Append Posts
                    $('.cases-list').append($posts);

                    // Replace pagination
                    $('.pagination-wrap').empty().append($pagination);

                    const maxPages = Number($('.btn-load-more').attr('data-max-pages'));
                    const currentPage = Number($('.btn-load-more').attr('data-current-page'));
                    if (currentPage >= maxPages) {
                        $('.btn-load-more').addClass('disabled')
                    } else {
                        $('.btn-load-more').removeClass('disabled')
                    }

                    /* History Replace State */
                    {
                        let url = window.location.pathname;
                        let newUrl;
                        let regex = /page\/(\d{1,100000})/;
                        let page = url.match(regex);

                        if (page) {

                            // 2+ Page
                            newUrl = url.replace(regex, 'page/' + ++page[1]);

                            if (location.search) {
                                newUrl += location.search;
                            }

                            window.history.replaceState('Blog Page ' + ++page[1], 'Blog Page ' + ++page[1], newUrl);

                        } else {

                            // 1 Page
                            newUrl = url + 'page/2';
                            if (location.search) {
                                newUrl += location.search;
                            }
                            window.history.replaceState('Blog Page 2', 'Blog Page 2', newUrl);
                        }
                    }

                    setTimeout(() => {
                        $('html,body').removeClass('wait');
                        $('.filters-block').removeClass('disabled')
                    }, 150);
                }
            });

        })
    }

    /* ----------------------------------- Case Studies Filter  ------------------------------------ */
    {
        function filterCaseStudies() {
            let data = new FormData();

            // Ajax Settings
            data.append('action', 'cases_filter');
            data.append('page', $('.btn-load-more').attr('data-current-page'));
            data.append('pagenum_link', getPageNumLink());

            if (getGETParam('date')) {
                data.append('date', getGETParam('date'));
            }

            if (getGETParam('category')) {
                data.append('category', getGETParam('category'));
            }

            $.ajax({
                url: PHP.ajaxurl,
                data: data,
                type: 'POST',
                cache: false,
                dataType: 'json',
                processData: false,
                contentType: false,
                beforeSend: function (xhr) {
                    $('html,body').addClass('wait');
                    $('.filters-block').addClass('disabled')
                },
                complete: function (response) {
                    let $posts = $(response.responseJSON.posts);
                    let $pagination = $(response.responseJSON.pagination);

                    // Append Posts
                    $('.cases-list').empty().append($posts);

                    // Replace pagination
                    $('.pagination-wrap').empty().append($pagination);

                    const maxPages = Number($('.btn-load-more').attr('data-max-pages'));
                    const currentPage = Number($('.btn-load-more').attr('data-current-page'));
                    if (currentPage >= maxPages) {
                        $('.btn-load-more').addClass('disabled')
                    } else {
                        $('.btn-load-more').removeClass('disabled')
                    }


                    // Remove /page from url
                    let url = window.location.pathname;
                    let newUrl;
                    let regex = /\/{1,3}page\/(\d{1,100000})/;
                    let page = url.match(regex);
                    let pageTitle = $('title').text();
                    if (page) {
                        newUrl = url.replace(regex, '');
                        if (location.search) {
                            newUrl += location.search;
                        }
                        window.history.replaceState(null, pageTitle, newUrl);
                    }


                    setTimeout(() => {
                        $('html,body').removeClass('wait');
                        $('.filters-block').removeClass('disabled')
                    }, 150);
                }
            });
        }

        // Date Filter
        $('.case-studies-template select[name="date"]').on('change selectric-change', function (event, element, selectric) {
            setGETParam('date', encodeURIComponent(this.value));
            filterCaseStudies();
        });


        // Category Filter
        $('.case-studies-template select[name="category"]').on('change selectric-change', function (event, element, selectric) {
            setGETParam('category', encodeURIComponent(this.value));
            filterCaseStudies();
        });

        // Category Filter
        $('.case-studies-template  .filters-block__area-list button').on('click', function (e) {
            e.preventDefault();
            $(this).siblings('button').removeClass('active');
            $(this).addClass('active');
            setGETParam('category', encodeURIComponent($(this).attr('data-name')));
            filterCaseStudies();
        });
    }

    /* ----------------------------------- Blog Load More  ------------------------------------ */
    {
        $(document).on('click', '.page-blog .btn-load-more', function () {
            let data = new FormData();

            // Ajax Settings
            data.append('nonce_code', PHP.load_more_nonce);
            data.append('action', 'blog_load_more');
            data.append('query', PHP.posts);
            data.append('page', $('.btn-load-more').attr('data-current-page'));
            data.append('pagenum_link', getPageNumLink());

            if (getGETParam('date')) {
                data.append('date', getGETParam('date'));
            }

            if (getGETParam('category')) {
                data.append('category', getGETParam('category'));
            }

            $.ajax({
                url: PHP.ajaxurl,
                data: data,
                type: 'POST',
                cache: false,
                dataType: 'json',
                processData: false,
                contentType: false,
                beforeSend: function (xhr) {
                    $('html,body').addClass('wait');
                    $('.filters-block').addClass('disabled')
                },
                complete: function (response) {
                    let $posts = $(response.responseJSON.posts);
                    let $pagination = $(response.responseJSON.pagination);

                    // Append Posts
                    $('.posts-grid').append($posts);

                    // Replace pagination
                    $('.pagination-wrap').empty().append($pagination);

                    const maxPages = Number($('.btn-load-more').attr('data-max-pages'));
                    const currentPage = Number($('.btn-load-more').attr('data-current-page'));
                    if (currentPage >= maxPages) {
                        $('.btn-load-more').addClass('disabled')
                    } else {
                        $('.btn-load-more').removeClass('disabled')
                    }

                    /* History Replace State */
                    {
                        let url = window.location.pathname;
                        let newUrl;
                        let regex = /page\/(\d{1,100000})/;
                        let page = url.match(regex);

                        if (page) {

                            // 2+ Page
                            newUrl = url.replace(regex, 'page/' + ++page[1]);

                            if (location.search) {
                                newUrl += location.search;
                            }

                            window.history.replaceState('Blog Page ' + ++page[1], 'Blog Page ' + ++page[1], newUrl);

                        } else {

                            // 1 Page
                            newUrl = url + 'page/2';
                            if (location.search) {
                                newUrl += location.search;
                            }
                            window.history.replaceState('Blog Page 2', 'Blog Page 2', newUrl);
                        }
                    }

                    setTimeout(() => {
                        $('html,body').removeClass('wait');
                        $('.filters-block').removeClass('disabled')
                    }, 150);
                }
            });

        })
    }

    /* ----------------------------------- Blog Filter  ------------------------------------ */
    {
        function filterBlogPosts() {
            let data = new FormData();

            // Ajax Settings
            data.append('action', 'blog_filter');
            data.append('page', $('.btn-load-more').attr('data-current-page'));
            data.append('pagenum_link', getPageNumLink());

            if (getGETParam('date')) {
                data.append('date', getGETParam('date'));
            }

            if (getGETParam('category')) {
                data.append('category', getGETParam('category'));
            }

            $.ajax({
                url: PHP.ajaxurl,
                data: data,
                type: 'POST',
                cache: false,
                dataType: 'json',
                processData: false,
                contentType: false,
                beforeSend: function (xhr) {
                    $('html,body').addClass('wait');
                    $('.filters-block').addClass('disabled')
                },
                complete: function (response) {
                    let $posts = $(response.responseJSON.posts);
                    let $pagination = $(response.responseJSON.pagination);

                    // Append Posts
                    $('.posts-grid').empty().append($posts);

                    // Replace pagination
                    $('.pagination-wrap').empty().append($pagination);

                    const maxPages = Number($('.btn-load-more').attr('data-max-pages'));
                    const currentPage = Number($('.btn-load-more').attr('data-current-page'));
                    if (currentPage >= maxPages) {
                        $('.btn-load-more').addClass('disabled')
                    } else {
                        $('.btn-load-more').removeClass('disabled')
                    }


                    // Remove /page from url
                    let url = window.location.pathname;
                    let newUrl;
                    let regex = /\/{1,3}page\/(\d{1,100000})/;
                    let page = url.match(regex);
                    let pageTitle = $('title').text();
                    if (page) {
                        newUrl = url.replace(regex, '');
                        if (location.search) {
                            newUrl += location.search;
                        }
                        window.history.replaceState(null, pageTitle, newUrl);
                    }


                    setTimeout(() => {
                        $('html,body').removeClass('wait');
                        $('.filters-block').removeClass('disabled')
                    }, 150);
                }
            });
        }

        // Date Filter
        $('.page-blog select[name="date"]').on('change selectric-change', function (event, element, selectric) {
            setGETParam('date', encodeURIComponent(this.value));
            filterBlogPosts();
        });


        // Category Filter
        $('.page-blog select[name="category"]').on('change selectric-change', function (event, element, selectric) {
            setGETParam('category', encodeURIComponent(this.value));
            filterBlogPosts();
        });

        // Category Filter
        $('.page-blog  .filters-block__area-list button').on('click', function (e) {
            e.preventDefault();
            $(this).siblings('button').removeClass('active');
            $(this).addClass('active');
            setGETParam('category', encodeURIComponent($(this).attr('data-name')));
            filterBlogPosts();
        });
    }

    /* ----------------------------------- Team Tabs Section ------------------------------------ */
    {
        if (browser.w > 991) {
            $('.team-grid .team-member').on('click', function (e) {
                e.preventDefault();
                const index = $(this).attr('data-index');
                $(this).addClass('active').parent().siblings('.team-member__wrap').find('.team-member').removeClass('active');

                $('.team-preview .team-member-view').each(function () {
                    if ($(this).attr('data-index') == index) {
                        $(this).addClass('active');
                    } else {
                        $(this).removeClass('active');
                    }
                })
            });
            $('.team-grid .team-member__wrap .name').on('click', function (e) {
                e.preventDefault();
                const index = $(this).attr('data-index');
                $(this).siblings('.team-member').addClass('active').parent().siblings('.team-member__wrap').find('.team-member').removeClass('active');

                $('.team-preview .team-member-view').each(function () {
                    if ($(this).attr('data-index') == index) {
                        $(this).addClass('active');
                    } else {
                        $(this).removeClass('active');
                    }
                })
            });
            $('.team-member-view  .prev').on('click', function (e) {
                e.preventDefault();

                if ($('.team-preview .team-member-view.active').prev('.team-member-view').length) {

                    // Prev
                    $('.team-preview .team-member-view.active').removeClass('active').prev('.team-member-view').addClass('active');
                    $('.team-grid .team-member').each(function () {
                        if ($(this).attr('data-index') == $('.team-preview .team-member-view.active').attr('data-index')) {
                            $(this).addClass('active');
                        } else {
                            $(this).removeClass('active');
                        }
                    })

                } else {

                    // Last
                    $('.team-preview .team-member-view.active').removeClass('active')
                    $('.team-preview .team-member-view').last().addClass('active');
                    $('.team-grid .team-member').each(function () {
                        if ($(this).attr('data-index') == $('.team-preview .team-member-view.active').attr('data-index')) {
                            $(this).addClass('active');
                        } else {
                            $(this).removeClass('active');
                        }
                    })

                }

            });
            $('.team-member-view  .next').on('click', function (e) {
                e.preventDefault();

                if ($('.team-preview .team-member-view.active').next('.team-member-view').length) {

                    // Prev
                    $('.team-preview .team-member-view.active').removeClass('active').next('.team-member-view').addClass('active');
                    $('.team-grid .team-member').each(function () {
                        if ($(this).attr('data-index') == $('.team-preview .team-member-view.active').attr('data-index')) {
                            $(this).addClass('active');
                        } else {
                            $(this).removeClass('active');
                        }
                    })

                } else {

                    // Last
                    $('.team-preview .team-member-view.active').removeClass('active')
                    $('.team-preview .team-member-view').first().addClass('active');
                    $('.team-grid .team-member').each(function () {
                        if ($(this).attr('data-index') == $('.team-preview .team-member-view.active').attr('data-index')) {
                            $(this).addClass('active');
                        } else {
                            $(this).removeClass('active');
                        }
                    })

                }

            });
        }

        if (browser.w <= 991 && document.querySelector('.team-slider-wrapper__swiper')) {
            const TeamSliderswiper = new Swiper('.team-slider__swiper', {
                direction: 'horizontal',
                loop: true,
                centeredSlides: true,
                slidesPerView: 'auto',
                slideToClickedSlide: true
            });

            TeamSliderswiper.on('slideChange', function () {
                const index = Number($('.team-grid .team-member__wrap.swiper-slide-active .team-member').attr('data-index')) + 1;
                $('.team-preview .team-member-view').each(function () {
                    if (Number($(this).attr('data-index')) == index) {
                        $(this).addClass('active')
                    } else {
                        $(this).removeClass('active')
                    }
                })
            });
        }
    }

    /* ----------------------------------- Contact Form ------------------------------------ */
    {
        var wpcf7Elm = document.querySelector('.wpcf7');
        if (wpcf7Elm) {

            $(wpcf7Elm).find(".wpcf7-form-control[aria-required='true']").prop('required', true);
            $(wpcf7Elm).find(".wpcf7-form").removeAttr('novalidate');

            wpcf7Elm.addEventListener('wpcf7beforesubmit', function (event) {
                $('body').addClass('wait');
            }, false);

            wpcf7Elm.addEventListener('wpcf7invalid', function (event) {
                $('body').removeClass('wait');

                Swal.fire({
                    icon: 'error',
                    title: PHP.cf_error_notification.title,
                    text: PHP.cf_error_notification.text,
                    confirmButtonText: PHP.cf_error_notification.btn_text
                });

            }, false);

            wpcf7Elm.addEventListener('wpcf7submit', function (event) {
                $('body').removeClass('wait');
            }, false)

            wpcf7Elm.addEventListener('wpcf7mailsent', function (event) {
                Swal.fire({
                    icon: 'success',
                    title: PHP.cf_success_notification.title,
                    text: PHP.cf_success_notification.text,
                    confirmButtonText: PHP.cf_success_notification.btn_text
                });

                $('body').removeClass('wait');
            }, false);

            wpcf7Elm.addEventListener('wpcf7mailfailed', function (event) {

                Swal.fire({
                    icon: 'error',
                    title: PHP.cf_error_notification.title,
                    text: PHP.cf_error_notification.text,
                    confirmButtonText: PHP.cf_error_notification.btn_text
                });

                $('body').removeClass('wait');

            }, false);

        }
    }

    /* ----------------------------------- Global Contact Form ------------------------------------ */
    {
        $('.form-global-contact').on('submit', function (e) {
            e.preventDefault();
            const form = $(this);

            if (form.hasClass('disabled')) {
                return false;
            }

            let data = new FormData();
            data.append('nonce_code', PHP.form_global_contact_nonce);
            data.append('action', 'form_global_contact_ajax_handler');

            data.append('firstname', form.find('input[name="firstname"]').val());
            data.append('email', form.find('input[name="email"]').val());
            data.append('phone', form.find('input[name="phone"]').val());
            data.append('subject', form.find('input[name="subject"]').val());
            data.append('message', form.find('textarea[name="message"]').val());

            data.append('url', location.href);
            data.append('page_title', $('title').text());

            $.ajax({
                url: PHP.ajaxurl,
                data: data,
                type: 'POST',
                cache: false,
                dataType: 'json',
                processData: false,
                contentType: false,
                beforeSend: function (xhr) {
                    $('body').addClass('wait')
                    form.addClass('disabled')
                    form.find('button[type="submit"]').addClass('disabled wait')
                    form.find('.error-message').addClass('hidden')
                },
                complete: function (response) {
                    if (response.responseJSON && response.responseJSON.form && response.responseJSON.form === 'true') {

                        // Reset Form Fields
                        form.trigger('reset');

                        location.href = PHP.thank_you_page_url
                            /*
                            // Success Message
                            Swal.fire({
                                icon: 'success',
                                title: PHP.cf_success_notification.title,
                                text: PHP.cf_success_notification.text,
                                confirmButtonText: PHP.cf_success_notification.btn_text
                            }).then((result) => {
                                location.href = PHP.thank_you_page_url
                            });
                         */

                    } else {
                        if (response.responseJSON && response.responseJSON.error) {
                            form.find('.error-message').each(function () {

                                if (response.responseJSON.error.includes($(this).attr('data-error'))) {
                                    $(this).removeClass('hidden')
                                } else {
                                    $(this).addClass('hidden')
                                }

                            })
                        } else {

                            // Error Message
                            Swal.fire({
                                icon: 'error',
                                title: PHP.cf_error_notification.title,
                                text: PHP.cf_error_notification.text,
                                confirmButtonText: PHP.cf_error_notification.btn_text
                            });

                        }
                    }

                    setTimeout(() => {
                        form.removeClass('disabled');
                        $('body').removeClass('wait');
                        form.find('button[type="submit"]').removeClass('disabled wait');
                    }, 10);
                }
            });


        })
    }

    /* ----------------------------------- Newsletter Form ------------------------------------ */
    {
        $('.form-newsletter').on('submit', function (e) {
            e.preventDefault();
            const form = $(this);

            if (form.hasClass('disabled')) {
                return false;
            }

            let data = new FormData();
            data.append('nonce_code', PHP.form_global_contact_nonce);
            data.append('action', 'form_newsletter_ajax_handler');

            data.append('email', form.find('input[name="email"]').val());

            data.append('url', location.href);
            data.append('page_title', $('title').text());

            $.ajax({
                url: PHP.ajaxurl,
                data: data,
                type: 'POST',
                cache: false,
                dataType: 'json',
                processData: false,
                contentType: false,
                beforeSend: function (xhr) {
                    $('body').addClass('wait')
                    form.addClass('disabled')
                    form.find('button[type="submit"]').addClass('disabled wait')
                    form.find('.error-message').addClass('hidden')
                },
                complete: function (response) {
                    if (response.responseJSON && response.responseJSON.form && response.responseJSON.form === 'true') {

                        // Reset Form Fields
                        form.trigger('reset');

                        // Success Message
                        Swal.fire({
                            icon: 'success',
                            title: PHP.newsletter_success_notification.title,
                            text: PHP.newsletter_success_notification.text,
                            confirmButtonText: PHP.newsletter_success_notification.btn_text
                        });

                    } else {
                        if (response.responseJSON && response.responseJSON.error) {


                            // Error Message
                            Swal.fire({
                                icon: 'error',
                                title: PHP.newsletter_error_notification.title,
                                text: 'Please provide a valid email address.',
                                confirmButtonText: 'Ok'
                            });

                        } else {

                            // Error Message
                            Swal.fire({
                                icon: 'error',
                                title: PHP.newsletter_error_notification.title,
                                text: PHP.newsletter_error_notification.text,
                                confirmButtonText: PHP.newsletter_error_notification.btn_text
                            });

                        }
                    }

                    setTimeout(() => {
                        form.removeClass('disabled');
                        $('body').removeClass('wait');
                        form.find('button[type="submit"]').removeClass('disabled wait');
                    }, 10);
                }
            });


        })
    }

});
